import {useState, useEffect} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useParams} from 'react-router-dom'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {updatePassword, refreshToken} from '../core/_requests'

const initialValues = {
  password: '',
}

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

function Form() {
  const {id} = useParams()

  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const response = await updatePassword(id, values.password)

        if (response.status !== 200) {
          setHasErrors(true)
          setLoading(false)
        } else {
          setHasErrors(false)
          setLoading(false)
          setTimeout(() => {
            window.location.href = `${window.location.origin}/login`
          }, 3000)
        }
      } catch (error) {
        console.log('error')
        setHasErrors(true)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <div className='card'>
      <div className='card-body'>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-center mb-10'>
            {/* begin::Title */}
            <h1 className='text-dark fw-bolder mb-3'>Change Password</h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-500 fw-semibold fs-6'>Enter new password .</div>
            {/* end::Link */}
          </div>

          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Sorry, looks like there are some errors detected, please try again.
              </div>
            </div>
          )}

          {hasErrors === false && (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>Succes update your password. Please login back</div>
            </div>
          )}
          {/* end::Title */}

          {/* begin::Form group */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Password Baru</label>
            <input
              type='password'
              placeholder=''
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.password && formik.errors.password},
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button type='submit' id='kt_password_reset_submit' className='btn btn-primary'>
              <span className='indicator-label'>Submit</span>
              {loading && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Form group */}
        </form>
      </div>
    </div>
  )
}

export function ResetPassword() {
  const {id} = useParams()

  const [isValid, setValid] = useState(true)
  useEffect(() => {
    async function getRefreshToken() {
      try {
        if (id) {
          const response = await refreshToken(id)
          if (response.status !== 200) {
            setValid(false)
          } else {
            setValid(true)
          }
        }
      } catch (error) {
        setValid(false)
      }
    }
    getRefreshToken()
  }, [])

  return <>{isValid ? <Form /> : (window.location.href = `${window.location.origin}/error/404`)}</>
}
